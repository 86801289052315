import i18n from 'i18next';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import { withTranslation } from 'react-i18next';

const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
};

function NavDropdownLanguage({ t, changeLang }) {
    return (
        <Dropdown as={Nav.Item}>
            <Dropdown.Toggle as={Nav.Link}>
                {i18n.languages[0] === 'en' ? t('englishLanguage') : t('spanishLanguage')}
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight>
                <Dropdown.Item as="button" onClick={() => { changeLanguage('en'); changeLang('en') }}>{t('englishLanguage')}</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => { changeLanguage('es'); changeLang('es') }}>{t('spanishLanguage')}</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default withTranslation()(NavDropdownLanguage);

