import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import Logout from './Logout';
import NavBarBrand from './NavBarBrand';
import NavDropdownLanguage from './NavDropdownLanguage';
import { withTranslation } from 'react-i18next';
import ZenDesk from './ZenDesk';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function NavBarUser({ t }) {
    const pathname = window.location.pathname;
    var activeTab = pathname.substring(pathname.lastIndexOf('/') + 1);
    const defaultLang = localStorage.getItem('i18nextLng');
    const [languaje, setLanguaje] = useState(defaultLang);

    const changeLang = (lang) => {
        setLanguaje(lang);
    }

    return (
        <>
            <Navbar id="navBarUser" bg="light" variant="light" className="green-background mb-2" collapseOnSelect expand="sm">
                <NavBarBrand href="/home" />
                <Navbar.Toggle />
                <Navbar.Collapse>
                    <Nav activeKey={activeTab}>
                        <Nav.Item>
                            <Nav.Link as={Link} eventKey="home" to="/home">{t('swagger')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={Link} eventKey="manuals" to="/home/manuals">{t('manuals.title')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={Link} eventKey="examples" to="/home/examples">{t('examples.title')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item style={{ alignContent: 'center' }}>
                            <a className="color-a" href='https://vidsigner.sharepoint.com/:u:/s/PublicAssets/EUTqgIC0b1JGjNdDWZMDS3IBt_qBzXOXL-yzYshM5pQmuQ?e=kk1sRY&download=1' download="openApi.yaml">Open API</a>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={Link} eventKey="profile" to="/home/profile">{t('profile.title')}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Nav className="ml-auto">
                        <NavDropdownLanguage changeLang={changeLang} />
                        <Logout />
                        <ZenDesk languaje={languaje} />
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}

export default withTranslation()(NavBarUser);

